.container {
  margin-top: 20px;
  font-family: 'Nunito', sans-serif !important;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9c9c;
  border-radius: 5px;
}

.color {
  background-color: #f5f5f5;
}

.heading {
  background: #FFFFFF;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.heading2 {
  background-color: 'white';
  font-size: x-large;
  text-align: center;
  /* margin-top: 25px; */
  /* height: 50px; */
}

.wfhCam {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-left: 80px;
  margin-top: 20px;
}

.r1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* background-color: #f5f5f5; */
}

.r2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* background-color: #F5F5F5; */
}

.device-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.051);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 2%);
  border-radius: 10px;
  width: 80%;
  height: 4.5em;
  /* margin: 1.5rem 0; */
}

.device-time1 {
  font-size: 11px;
  opacity: 0.5;
  font-weight: 700;
}

.device-time2 {
  font-size: 14px;
  opacity: 0.8;
  font-weight: 700;
}

.label-above-chart {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: -20px;
  align-items: center;
}

.span-under-icon {
  margin: 0 0.5rem 0 0.8rem;
  background: rgb(230, 230, 233);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
}

@media (min-width : 991px) and (max-width : 1200px) {
  .wfhCam {
    margin-left: 90px;
  }

  .heading {
    /* height: 100px; */
  }

}

@media (min-width : 769px) and (max-width : 992px) {
  .heading {
    /* height: 100px; */
  }
}

@media (max-width : 770px) {
  .wfhCam {
    text-align: center;
  }

  .r1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: #f5f5f5; */
  }

  .r2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: #f5f5f5; */
  }

  .indicator {
    margin: auto;
  }

  .co2 {
    margin: auto;
    margin-top: 40px;
  }

  .heading {
    /* height: 100px; */
  }
}

@media (max-width : 480px) {
  .wfhCam {
    margin-left: 80px;
  }

  .heading {
    /* height: 120px; */
  }
}

.indicator {
  background-color: #ffffff;
  text-align: center;
  height: 150px;
  width: 220px;
  font-size: x-large;
  margin-top: 30px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.co2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  text-align: center;
  height: 150px;
  width: 220px;
  font-size: x-large;
  margin-top: 30px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.label-for-card {
  opacity: 0.5;
  color: #2E375B;
  font-size: 1rem;
  line-height: 15px;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width : 480px) {
  .indicator {
    text-align: center;
    height: 120px;
    width: 150px;
    font-size: large;
    margin-top: 30px;
  }

  .co2 {
    text-align: center;
    height: 120px;
    width: 150px;
    font-size: large;
    margin-top: 30px;
  }
}


h1 {
  padding-top: 20px;
  color: #03045e;
}

.nav-tabs {
  background-color: #2E375B;
}

.nav-item {
  color: #38D68B;
  border-bottom: 1px solid #38D68B;
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  opacity: 0.9;
  font-weight: 700;
}

.nav-link {
  display: flex !important;
  padding: 0.5rem 1rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none !important;
}

a:hover {
  color: #38D68B !important;
  text-decoration: underline !important;
  opacity: 0.8 !important;
}

.nav-tabs .nav-link.active,
.nav-item.show {
  color: #38D68B !important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 3px solid #38D68B !important;
  opacity: 1;
}

.selectActivity {
  margin-top: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}