.info-body {
    width: 100%;
    min-height: 65px;
    height: inherit;
    margin: auto;
    border-radius: 10px;

}

.info-body-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1.3rem 1rem;
}

.info-icon {
    align-self: flex-start;
}

.info-text {
    /* Nunito Regular 13 */

    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    padding-left: 14px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;

    /* black */

    color: #444444;
}

.info-button-parent {
    margin: 0.5rem 0;
}