.add-new-device-body {
    width: 100%;
    min-height: 3.2rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    border: dotted;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.add-new-device-text {
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #2E375B;
}