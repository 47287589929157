.rectangle-card-timP-body {
    width: 100%;
    min-height: 5.2rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.green-text {
    font-weight: bold;
    color: #6ddf74;
}

.red-text {
    font-weight: bold;
    color: red;
    /* Add any default text styles here */
}

.rectangle-card-timP-body-top {
    display: flex;
    justify-content: space-between;
}

.rectangle-card-timP-body-top-content1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #2E375B;
}



.rectangle-card-timP-body-top-content1-setUp-done {
    font-weight: 700;
    font-size: 0.8rem;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 0.2rem 0.5rem;
}

.rectangle-card-timP-body-top-content2 {
    /* font-family: 'Nunito'; */
    /* font-size: 12px;
    padding: 0.1rem 1rem;
    color: #FF9777;
    background: transparent;
    border: 1px solid #FF9777;
    border-radius: 5px; */
}

.rectangle-card-status-for-space-reset-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rectangle-card-space-capacity {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: rgba(68, 68, 68, 0.6);
    float: right;
    /* display: flex;
    align-items: center;
    flex-direction: column; */

}

.rectangle-card-timP-body-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: rgba(68, 68, 68, 0.6);
    display: flex;
    flex-direction: column;
}

.rectangle-card-timp-body-top-content2 {
    height: 18px;
    width: 52px;
    margin-top: 2px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Nunito;
    font-size: 9px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 15px;

    color: #FF9777;
    background: transparent;
    border: 1px solid #FF9777;
    border-radius: 5px;

}

.rectangle-card-timp-body-top-content2-dark {
    height: 18px;
    width: 52px;
    margin-top: 2px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Nunito;
    font-size: 9px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;


    color: #FFFFFF;
    background: #FF9777;
    border: 1px solid #FF9777;
    border-radius: 5px;

}

.rectangle-card-timp-capacity {

    width: 67px;
    height: 17px;


    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    letter-spacing: -0.01em;

    color: rgba(68, 68, 68, 0.6);
}